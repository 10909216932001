html {
  height: 100%;
}
body {
  margin: 0;
  padding: 0;
  font-family: Roboto;
  height: 100%;
}
@font-face {
  font-family: founders_bold;
  src: url(fonts/FoundersGroteskBold.otf);
  font-weight: bold;
}
@font-face {
  font-family: founders;
  src: url(fonts/FoundersGroteskRegular.otf);
}
@font-face {
  font-family: HelveticaNeue;
  src: url(fonts/FoundersGroteskRegular.otf);
  font-weight: medium;
}

@font-face {
  font-family: font_bold;
  src: url(fonts/FoundersGrotesk_Medium.otf);
  font-weight: medium;
}
@font-face {
  font-family: light;
  src: url(fonts/FoundersGroteskLight.otf);
}
@font-face {
  font-family: semibold;
  src: url(fonts/FoundersGrotesk_Semibold.otf);
}