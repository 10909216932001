

@media only screen and (min-width: 1000px) {
    #img {
     width:500px;
    height: 100px;
    margin-top:130px;
    }
    .suggesion{
        width: 30em;
    }
  }
 


  @media only screen and (max-width: 999px) {
    #img {
        height:60px;
        width: 350px;
        margin-top: 130px;
        
       }
       .suggesion{
        width: 20em;
    }
  }