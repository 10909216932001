#mainDiv{
    display: inline;
    flex-direction: column;
    
    
}
.leftCol {
    height: 85%;
    position: fixed;
    overflow-y: scroll;
    width: 30rem;
    overflow-y: scroll;
    top: 1;
    /* background-color:#F0F0F0	; */
    
    /* height: 100%; */
    min-height: fit-content;
    
   
}
#reservedspace:hover{
   
    background-color:#39354E;
    color:white;    
    height: 100%;
}