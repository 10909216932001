
#div {
    width: 100%;
    margin: auto;
  }
  
  #p {
    border-top: 2px dashed;
    border-color: rgb(8, 216, 136) !important;
    margin:0;
    padding: 30px;
    counter-increment: section;
    position: relative;
    
  }
  .video-fluid {
    width: 100%;
    height: 40rem;
    background: rgba(0, 0, 0, 0.50);
  }
  
  
.overlay {
    position:absolute;
    top:0;
    left:0;
    z-index:1;
    width: 100%;
    height: 40rem;
    text-align: center;
    font-family: Cambria, Cochin, Georgia, Times, 'Times New Roman', serif;
}
  
  
  
  #p:nth-child(even):before {
    content: counter(section);
    right: 100%; 
    margin-right: -20px;
    position: absolute;
    border-radius: 50%;
    height: 30px;
    width: 30px;
    background-color: rgb(8, 216, 136);
    text-align:center;
    color: white;
    font-size: 110%;
  }
  
  #p:nth-child(odd):before {
    content: counter(section);
    left: 100%; 
    margin-left: -20px;
    position: absolute;
    border-radius: 50%;
    height: 30px;
    width: 30px;
    background-color:rgb(8, 216, 136);
    text-align:center;
    color: white;
    font-size: 110%;
  }
  
  
  
  #p:nth-child(even) {
    border-left: 2px dashed;
    border-top-left-radius: 30px;
    border-bottom-left-radius: 30px;
    margin-right: 30px; 
    padding-right: 0;
  }
  
  #p:nth-child(odd) {
    border-right: 2px dashed;
    border-top-right-radius: 30px;
    border-bottom-right-radius: 30px;
    margin-left: 30px; 
    padding-left: 0;
  }
  
  #p:first-child {
    border-top: 0;
    border-top-right-radius:0;
    border-top-left-radius:0;
    text-align: right;
  }
  #p:nth-child(2){
    border-top: 1;
    border-top-right-radius: 1;
    border-top-left-radius: 1;
    text-align: left;
  }
  #p:nth-child(3){
    text-align: right;
  }
  #p:nth-child(5){
    text-align: right;
  }
  
  #p:last-child {
    border-bottom-right-radius:0;
    border-bottom-left-radius:0;
  }


#ijmg {
  background: linear-gradient( rgb(0, 0, 0.9), rgba(0, 0, 0, 0.9) ), url('./images/officeLong.jpg');
 -webkit-background-size: cover;
 -moz-background-size:  cover;
 -o-background-size: cover;
 background-size: cover;
 height: 60vh;
 border-radius:50px ;
 
}
#ijmg1 {
  background: linear-gradient(  rgba(0, 0, 0, 0.9), rgba(0, 0, 0, 0.9) ), url('./images/meeting.jpg');
 -webkit-background-size: cover;
 -moz-background-size:  cover;
 -o-background-size: cover;
 background-size: cover;
 height: 60vh;
 border-radius:50px ;
 
}
#ijmg2 {
  background: linear-gradient( rgba(0, 0, 0, 0.9), rgba(0, 0, 0, 0.9) ), url('./images/recording.jpg');
 -webkit-background-size: cover;
 -moz-background-size:  cover;
 -o-background-size: cover;
 background-size: cover;
 height: 60vh;
 border-radius:50px ;
 
}
.shape-to-left{
  display: inline-block;
  position: absolute;


}
.firstinslider{
  background-image: url(./images/Audio.jpg);
  background-position: center;
  /* transform: translate(-30%, 0%);             */
  background-repeat: no-repeat;
  background-size: cover;
  border-radius: 60% 40% 30% 70% / 60% 30% 70% 40%;
  /* width:'100%; */
  animation: morph 2s ease-in-out infinite;
  float: right;
}
.termsinmainpage:hover{
  text-decoration: underline;
}
@keyframes morph{
  0%{
    border-radius: 60% 40% 30% 70% / 60% 30% 70% 40%;
  }
  50%{
    border-radius: 30% 60% 70% 40% / 50% 60% 30% 60%;
  }
  100%{
    border-radius: 60% 40% 30% 70% / 60% 30% 70% 40%;
  }
}
.secondinslider{
  background-image: url(./images/lecture.jpg);
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  border-radius: 60% 40% 30% 70% / 60% 30% 70% 40%;
  /* width:'100%; */
  animation: morph-two 2s ease-in-out infinite;
  float: right;

}
@keyframes morph-two{
  0%{
    border-radius: 60% 40% 30% 70% / 60% 30% 70% 40%;
  }
  50%{
    border-radius: 30% 60% 70% 40% / 50% 60% 30% 60%;
  }
  100%{
    border-radius: 60% 40% 30% 70% / 60% 30% 70% 40%;
  }
}
.thirdinslider{
  background-image: url(./images/micro.jpg);
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  border-radius: 60% 40% 30% 70% / 60% 30% 70% 40%;
  /* width:'100%; */
  animation: morph-three 2s ease-in-out infinite;
  float: right;

}
@keyframes morph-three{
  0%{
    border-radius: 60% 40% 30% 70% / 60% 30% 70% 40%;
  }
  50%{
    border-radius: 30% 60% 70% 40% / 50% 60% 30% 60%;
  }
  100%{
    border-radius: 60% 40% 30% 70% / 60% 30% 70% 40%;
  }
}