@media only screen and (max-width: 766px) {
    #list {
      background-color: lightblue;
      /* display: none */
    }

    .makeStyles-drawerPaper-6{

      background-color: darkorchid;
    }

  }

  #NavLink:hover{
    background-color: rgb(8, 216, 136);
    color:white;
   
    padding:10px;
    width:100%;
    border-radius: 10%;
  }
  #spaces:hover{
    color: white;
    background-color:rgb(8, 216, 136);
    padding:10px;
    border-radius:10%
  }