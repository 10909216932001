/* .password-strength-meter {
    text-align: left;
  } */
  
  .password-strength-meter-progress {
    -webkit-appearance: none;
    appearance: none;
    width: 250px;
    height: 20px;
  }
  progress:before {
    content: attr(data-label);
    font-size: 0.8em;
    vertical-align: 0;
    color:white;
    /*Position text over the progress bar */
    position:absolute;
    padding-left:5px;
    text-shadow: 0px 1px 1px #000;
    /* left:0; */
    /* right:0; */
  }
  .password-strength-meter-progress::-webkit-progress-bar {
    background-color: #eee;
    border-radius: 3px;
  }
  
  .password-strength-meter-label {
    font-size: 14px;
  }
  
  .password-strength-meter-progress::-webkit-progress-value {
    border-radius: 2px;
    background-size: 35px 20px, 100% 100%, 100% 100%;
  }
  
  .strength-Weak::-webkit-progress-value {
    transition: width 0.5s;
    background-color: #F25F5C;

  }
  .strength-Fair::-webkit-progress-value {
    transition: width 0.5s;
    background-color: #FFE066;
  }
  .strength-Good::-webkit-progress-value {
    transition: width 0.5s;
    background-color: #247BA0;
  }
  .strength-Strong::-webkit-progress-value {
    transition: width 0.5s;
    background-color: #70C1B3;
  }


  .progress {
    height: 2.3em;
    width: 100%;
    background-color: #c9c9c9;
    position: relative;

  }
  .progress:before {
    content: attr(data-label);
    font-size: 1em;
    position: absolute;
    text-align: center;
    top: 5px;
    left: 0;
    right: 0;
    color:white;
    text-shadow: 0px 1px 1px #000;
  }
  .progress .value {
    display: inline-block;
    height: 100%;
    -webkit-transition: width .5s; /* For Safari 3.1 to 6.0 */
    transition: width .5s;
  }