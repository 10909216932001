.PropertyForm {
  height: 100%;
  width: 100%;
  display: grid;
}
#stepper {
  margin-left:240px;
  
}
.stepper{
  background-color: transparent;

}
@media only screen and (max-width: 766px) {
  #property-form{
    padding-left:0px !important;}
  #stepper{
    margin-left:-40px;
    
    
    
  }

#sub_time_form{
  padding-left:0 !important
}}
@media only screen and (max-width: 429px) {
  .submit_btn{ width:55% !important}
}
@media only screen and (max-width: 1300px) {
.time{
  width:50%
}
}
@media only screen and (max-width: 1100px) {
  .time{
    width:70%
  }
  }
@media only screen and (max-width: 1100px) {
  #property-form{
    
    /* margin:auto; */
    align-items: center;
  }
  .MuiGrid-align-items-xs-center {
    /* align-items: baseline !important; */
}
   #sub_div_media_form{
    /* margin-left:200px */
  }
  #sub_time_form{
    
  }
  }
  #property-form{
    padding-left:240px;
  }
  .stack_grid_st{
    max-width: 900px;
    background-color: green;
  }

  .MuiButtonBase-root{
    outline: 0 !important
  }
  #red {color:red; text-align:center}
  /* #stepper {
    margin-left:200px !important
  } */
  .img_btn{background-size:150px auto; background-repeat:no-repeat; width:150px; display:inline-block;cursor:pointer; height:100px; margin:10px;background-color: hotpink;}

  @media only screen and (max-width: 900px) {
    .img_btn{display:block;background-color: khaki;}}
  @media only screen and (max-width: 979px) {
   
    #sub_div_media_form{
      margin-left:0 !important;
     

    }

  }
  #spaceForm_grid{
    margin: auto;
  }
  @media only screen and (max-width: 1400px) {
  #spaceForm_grid{
    padding-left: 240px;
    margin: auto;
  }}
  @media only screen and (min-width: 980px) {
  .MuiStepConnector-lineVertical{
    border-top-style: solid;
    border-top-width: 1px;
    border-left: none !important;
  }
  .MuiStepper-vertical {
    flex-direction: row !important;
}
}

