* {
    margin: 0;
    padding: 0;
}
.imgbox {
    display: grid;
    height: 100%;
}
.center-fit {
    max-width: 100%;
    max-height: 100vh;
    margin: auto;
}
.react-calendar__navigation{border:none; background-color: white}
.react-calendar__tile{border:none}
.DayPicker-Day{
    border-radius: 0px!important; 
    border-bottom: solid 5px transparent  ;
    border-top: solid 5px transparent;  
    color:lightgrey;
    outline:0
} 
.DayPicker-Day--selected{border-radius: 0px; background-color:rgb(99, 240, 99)   !important}
div{border-radius: 0px;}
/* .DayPicker-Day--selected :hover{background-color: #4A90E2 !important;}  */
.DayPicker-Day--outside{display: none !important}
.DayPicker-wrapper{ outline: 0}
 /* .Fixed
{
    position: fixed;
    top: 100px;
    right:50px; 

 } */

/* .absoluted{
    position: absolute;
    top: 100px;
    right:50px;
} */
.DayPicker-Month{margin: 1em 1em 0 0 !important;}
/* .sticky {
    position: fixed;
    top: 0;
   
  } */

  * {
    margin: 0;
    padding: 0;
}
.imgbox {
    display: grid;
    height: 100%;
}
.center-fit {
    max-width: 100%;
    max-height: 100vh;
    margin: auto;
}
.react-calendar__navigation{border:none; background-color: white}
.react-calendar__tile{border:none}
.DayPicker-Day{
    border-radius: 0px!important; 
    border-bottom: solid 5px transparent  ;
    border-top: solid 5px transparent;  
    color:lightgrey;
    outline:0
} 
.DayPicker-Day--selected{border-radius: 0px; background-color:rgb(99, 240, 99)   !important}
div{border-radius: 0px;}
/* .DayPicker-Day--selected :hover{background-color: #4A90E2 !important;}  */
.DayPicker-Day--outside{display: none !important}
.DayPicker-wrapper{ outline: 0}
 /* .Fixed
{
    position: fixed;
    top: 100px;
    right:50px; 

 } */

/* .absoluted{
    position: absolute;
    top: 100px;
    right:50px;
} */
.DayPicker-Month{margin: 1em 1em 0 0 !important;}
/* .sticky {
    position: fixed;
    top: 0;
   
  } */
  .sticky {
    position: -webkit-fixed;
    position: fixed;
    top: 25%;
    padding: 30px;
    font-size: 20px;
    right:50px;
    border:solid thin lightgrey;
    border-radius: 5px;
    background-color:white;
    z-index: 40
  }
  #admin_form {
    margin:auto
  }
  @media only screen and (max-width: 500px) {
    #admin_form {
        margin:initial
      }
      .dialog_btn{
          margin:auto
      }
  }

  * {
    margin: 0;
    padding: 0;
}
.imgbox {
    display: grid;
    height: 100%;
}
.center-fit {
    max-width: 100%;
    max-height: 100vh;
    margin: auto;
}
.react-calendar__navigation{border:none; background-color: white}
.react-calendar__tile{border:none}
.DayPicker-Day{
    border-radius: 0px!important; 
    border-bottom: solid 5px transparent  ;
    border-top: solid 5px transparent;  
    color:lightgrey;
    outline:0
} 
.DayPicker-Day--selected{border-radius: 0px; background-color:rgb(99, 240, 99)   !important}
div{border-radius: 0px;}
/* .DayPicker-Day--selected :hover{background-color: #4A90E2 !important;}  */
.DayPicker-Day--outside{display: none !important}
.DayPicker-wrapper{ outline: 0}
 /* .Fixed
{
    position: fixed;
    top: 100px;
    right:50px; 

 } */

/* .absoluted{
    position: absolute;
    top: 100px;
    right:50px;
} */
.DayPicker-Month{margin: 1em 1em 0 0 !important;}
/* .sticky {
    position: fixed;
    top: 0;
   
  } */
  .sticky {
    position: -webkit-fixed;
    position: fixed;
    top: 25%;
    padding: 30px;
    font-size: 20px;
    right:50px;
    border:solid thin lightgrey;
    border-radius: 5px;
    background-color:white;
    z-index: 40
  }
  #admin_form {
    margin:auto
  }
  @media only screen and (max-width: 500px) {
    #admin_form {
        margin:initial
      }
      .dialog_btn{
          margin:auto
      }
  }