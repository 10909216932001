.mainbody{
    bottom: 0;
    width: 100%;
   
    
}
.NavBar{
    height: 6vh;
   
}
.yourspace{
    width: 11rem;
}
.BecomeOwner{
    height: 0;
    margin: 0;
    
}
ol {
    counter-reset: item;
}
html {
    scroll-behavior: smooth;
}
.pp {
   
    border-color: rgb(8, 216, 136) !important;
    margin:2;
    padding:30px;
    counter-increment: section;
    position: relative;
    
  }
  .pp:before {
    content: counter(section);
    right: 100%; 
    margin-left: 20px;
    position: absolute;
    border-radius: 50%;
    height: 40px;
    width: 40px;
    border:2px solid white;
   
    text-align:center;
    color: black;
    font-size: 110%;
  }
  .pp1{
    border-color:   rgb(8, 216, 136) !important;
    margin:2;
    padding:30px;
    counter-increment: section;
    position: relative;

  }
  .pp1::before{
    content: counter(section);
    right: 100%; 
    margin-left: 20px;
    position: absolute;
    border-radius: 50%;
    height: 40px;
    width: 40px;
    border:2px solid #1127cf;
   
    text-align:center;
    color: black;
    font-size: 110%;

  }
  
  
  .under_score{
    webkit-animation: blinker 1.5s ease infinite !important;
    animation: blinker 1.5s ease infinite !important;
  }
  .hostyourspace:hover{
      background-color: transparent;

  }
  


  
  


ol {
    list-style: none;
    counter-reset: my-awesome-counter;
    position: relative;

}

ol li {
    counter-increment: my-awesome-counter;
    margin-top: 45px;

}
ol #li{
    counter-increment: my-awesome-counter;
    
    
}
ol #li::before{
    content: counter(my-awesome-counter) "";
    border-radius: 100%;
    text-align: center;
    
    border: 1px solid #0000FF;
}


ol li::before {
    content: counter(my-awesome-counter) "";
    display: inline-block;
    width: 40px;
    height: 40px;
    margin: 0 20px 0 0;
    border: 1px solid white;
    border-radius: 100%;
    text-align: center;
    
    position: absolute; 
    
}
.officesSpaces::before{
    content: 'Our Spaces';
    font-size:37px;
    font-family: font_bold;
    
}

.firstimg{
    position: absolute;
}
.firstimg img{
    width:500px;
    position: relative;
    margin-top: 120px;
    margin-left: 500px;
   
    
   
}
.secondimg{
    position: absolute;
}
.secondimg img{
    width: 500px;
    position: relative; 
    margin-left: 370px; 
    margin-top: 300px; 
    
}

.thirdimg{
    position: absolute;  
}
.thirdimg img{
    width: 500px;
    position: relative;
    margin-left: 410px; 
     
   
    
}

.fourthimg{
    position: absolute;
}


.fourthimg img{
    width: 500px;
    position: relative;
    margin-top: 435px;
    margin-left: 360px;
    
   
    
}
.termsinLandingpage:hover{
    text-decoration: underline;
}

@media  screen and (min-width: 600px){
    .yourspaceDefination{
        height: 100vh;
        max-width: 90%;
        margin: auto;
        margin-top: 50px;
    }
    .circle::before{
        height: 100vh;
        border-bottom-right-radius: 100vh;
        width: 100vh;
        top: 0;
        left: 0;
        z-index: -1;
        position: fixed;
  
    }
    
    .circle {
     
      border-bottom-right-radius: 100vh;
      /* border-radius: 50%; */
      width: 100px;
      top: 0;
      left: 0;
      z-index: -1;
      height: 100vh;
      position: absolute;
      background-color: #08D888;
      
      /* position: fixed; */
      border-left: 50vw solid transparent;
  
  }

    .Defination{
        font-size: 85.53px;
        line-height: 1em;
        font-family: 'font_bold';
        color:black
    }
    
    .Description_text{
        line-height: 1em; 
        font-size: 31px;
        font-family: light; 
        color:black;
    }

}   

@media screen and (min-width:200px){
    
    .circle {
     
        background-color: #08D888; 
        color: white;
        z-index: -1;
        transform: translate(-50%, -50%);            /* move the circle left 50% of it's own width and up 50% of it's own height */
        border-radius: 50%;
        padding-top: 100%;                           /* this gives us a responsive square */
        position: absolute;
        /* position:sticky; */
        /* position: absolute;
        will-change: transform; */
        height: 100%;
        top:30%;                                     /* this vertically centers the circle */
        left:0;
        width:100%;
        min-width:400px;                              /* this is the miniimum dimensions to allow circle to fill smaller screens */
        min-height:400px;
        
        /* position: fixed; */
    
    }
    .yourspaceDefination{
        height: 70vh;
        max-width: 90%;
        margin: auto;
        
        margin-top: 50px;
    }
    .Defination{
        font-size: 60.53px;
        line-height: 1em;
        font-family: 'font_bold';
        color:black
    }
    .Description{
        margin-top: 0;
    }
}