.sidebar{
    height: 85vh;
    position: fixed;
    overflow-y: scroll;
    width: 25rem;
    margin-left:0px;
    
    top: 1;
    /* background-color:#F0F0F0	; */
    background-color:white;
    /* height: 100%; */
    min-height: fit-content;
}

#firstrow:hover{
    background-color: lightblue;
}
#secondrow:hover{
    background-color: lightblue;
}

.sidebarContent:hover{
    color:white;
    background-color:rgb(57, 53, 78);
}

.sidebarContent{
    padding:10px;
    cursor:pointer
}
.sidebarContent:active{
    color:white;
    background-color:rgb(57, 53, 78);
}

.content{
    width:100%;
    background-color:white;
    position:relative;
    margin-left:380px
}

